<template>
  <v-container>
    <v-row justify="center">
      <div
        style="
          width: 300px;
          background-color: #b2eeff;
          border-radius: 10px;
          text-align: left;
          font-family: notosansregular;
          margin-left: -330px;
        "
      >
        <p
          v-for="(j, i) in getMention(mention)"
          :key="i"
          style="margin: auto; padding: 10px"
        >
          {{ j }}
        </p>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {
  mounted() {
    //this.intervalId = setInterval(() => this.typing(this.mention), 15);
  },
  data() {
    return {
      divset: false,
      hint: "",
      index: 0,
    };
  },
  props: {
    _mention: {
      type: String,
      required: true,
    },
    _talkIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    talkIndex: {
      get() {
        return this._talkIndex;
      },
      set() {
        return this._talkIndex;
      },
    },
    mention: {
      get() {
        return this._mention;
      },
      set() {
        return this._mention;
      },
    },
  },
  methods: {
    typing(content) {
      this.$emit("typingStart");
      const text = document.querySelector(`.text${this.talkIndex}`);

      if (this.index < content.length) {
        let txt = content[this.index++];
        text.innerHTML += txt === "\n" ? "<br/>" : txt;
      } else {
        // 모든 텍스트가 출력되었을 때 setInterval을 종료
        clearInterval(this.intervalId);
        this.$emit("typingEnd");
      }
    },
    getMention() {
      return this.mention.split("\n");
    },
  },
};
</script>

<style>
.table {
  width: 600px;
  margin-left: 100px;
}

.notable {
  width: 300px;
}
</style>