<template>
  <div>
    <div>
      <v-row style="" justify="center">
        <v-card
          outlined
          width="800"
          height="750"
          style="border-radius: 15px; border: 1px solid rgb(240, 240, 240)"
        >
          <header>
            <v-card-title
              style="
                background-color: #1976d2;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                color: white;
              "
            >
              Math AI test
            </v-card-title>
          </header>
          <v-row style="margin-top: 10px" justify="center">
            <v-card
              id="scroll-target"
              outlined
              width="780"
              height="600"
              style="overflow-y: scroll; overflow-x: hidden"
            >
              <div
                v-for="(talk, i) in talk_session"
                :key="i"
                style="width: 700px; padding: 15px"
              >
                <usertalk
                  v-if="talk_session[i].role == 'user'"
                  style="margin-left: 220px; margin-top: 15px"
                  :_mention="talk_session[i].content[0].text"
                ></usertalk>
                <aitalk
                  @typingStart="goScroll()"
                  v-if="talk_session[i].role == 'assistant'"
                  style="margin-top: 15px"
                  :_talkIndex="i"
                  :_mention="talk_session[i].content"
                ></aitalk>
              </div>
            </v-card>
          </v-row>
          <v-row justify="center">
            <div style="width: 500px; margin-top: 10px; margin-left: 20px">
              <v-text-field
                :disabled="!isSend || !isStart"
                @keyup.enter="send()"
                outlined
                v-model="mention"
              ></v-text-field>
            </div>
            <v-btn
              outlined
              :disabled="!isSend || !isStart"
              @click="send()"
              style="
                margin-left: 8px;
                margin-top: 20px;
                background-color: #1976d2;
                color: white;
                font-family: notosansregular;
              "
              >SEND</v-btn
            >
            <v-btn
              :disabled="isStart"
              outlined
              @click="autosend()"
              style="
                margin-left: 8px;
                margin-top: 20px;
                background-color: red;
                color: white;
                font-family: notosansregular;
              "
              >Start</v-btn
            >
          </v-row>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import usertalk from "./Usertalk.vue";
import aitalk from "./AItalk4.vue";
import { talk3 } from "../api/api-list";

export default {
  name: "HelloWorld",
  data() {
    return {
      mention: "",
      default_mention: "gdfndgng",
      talk_session: [],
      talk_prompt: "",
      hint_prompt: "",
      scrollTarget: null,
      isSend: true,
      isStart: false,
    };
  },
  props: {
    prompt: {
      type: String,
      required: true,
    },
    imageuid: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  async created() {},
  mounted() {
    this.scrollTarget = document.getElementById("scroll-target");
  },
  updated() {
    this.scrollTarget.scrollTo({ top: this.scrollTarget.scrollHeight });
  },
  methods: {
    autosend() {
      this.isStart = true;

      this.mention = ".";
      this.send();
    },
    nextMention() {
      // setTimeout(() => {
      //   if (this.tempMentions[this.tempMentionIndex + 1]) {
      //     this.tempMentionIndex++;
      //     this.talk_session_temp.push({
      //       role: "assistant",
      //       content: this.tempMentions[this.tempMentionIndex],
      //       isTable: false,
      //     });
      //   }
      // }, 300);
    },
    isMobile() {
      return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    goScroll() {
      this.scrollTarget.scrollTo({ top: this.scrollTarget.scrollHeight });
    },
    async send() {
      if (!this.prompt) {
        window.alert("프롬프트가 없습니다.");
        return;
      }

      if (!this.imageuid) {
        window.alert("이미지가 없습니다.");
        return;
      }

      if (!this.mention) {
        return;
      }

      this.isSend = false;

      const grade = this.imageuid.split("_")[2][0];
      const semester = this.imageuid.split("_")[2][1];
      const unit = this.imageuid.split("_")[2][2];

      if (!this.talk_session.length) {
        this.talk_session = [
          {
            role: "system",
            content: this.prompt,
          },
          {
            role: "user",
            content: [
              { type: "text", text: this.mention },
              {
                type: "image_url",
                image_url: {
                  url: `${this.url}/${grade}-${semester}/unit${this.gettwonum(
                    unit
                  )}/question/${this.imageuid}.png`,
                },
              },
            ],
          },
        ];
      } else {
        this.talk_session.push({
          role: "user",
          content: [{ type: "text", text: this.mention }],
        });
      }

      this.mention = "";

      const res = await talk3({ session: this.talk_session });

      this.talk_session.push({ role: "assistant", content: res.data.message });

      this.isSend = true;
    },
    prettydate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minutes = date.getMinutes();
      const second = date.getSeconds();

      return `${year}-${this.gettwonum(month)}-${this.gettwonum(
        day
      )} ${this.gettwonum(hour)}:${this.gettwonum(minutes)}:${this.gettwonum(
        second
      )}`;
    },
    gettwonum(str) {
      return str.toString().padStart(2, "0");
    },
  },
  components: {
    usertalk,
    aitalk,
  },
};
</script>

<style>
.tableOn {
  margin-left: -20px;
}

.tableOff {
  margin-left: -170px;
}
</style>
