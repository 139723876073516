<template>
  <div>
    <v-row justify="center" style="margin-top: 50px">
      <div class="promptdiv">
        <textarea
          v-model="prompt"
          placeholder="프롬프트를 입력해주세요."
        ></textarea>
        <input
          v-model="imageuid"
          type="text"
          placeholder="이미지 UID 입력 ..."
          style="margin-top: 30px; width: 300px"
        />
        <img :src="imageURL" alt="" />
      </div>
      <div class="chatdiv">
        <aitutor3 :prompt="prompt" :imageuid="imageuid" :url="url"></aitutor3>
      </div>
    </v-row>
  </div>
</template>

<script>
import aitutor3 from "./aitutor3.vue";

export default {
  data() {
    return {
      prompt: "풀이를 알려줘",
      imageuid: "IMG_Q_311111",
      url: "https://argame3.blob.core.windows.net/math-learning-resources",
      imageURL: "",
    };
  },
  components: {
    aitutor3,
  },
};
</script>

<style scoped>
.promptdiv {
  width: 400px;
  height: 800px;
}

.chatdiv {
  margin-left: 50px;
}

.applyBtn {
  background-color: green;
  font-family: notosans;
  margin-left: 160px;
}

textarea {
  outline: none;
  border: 1px solid black;
  width: 400px;
  height: 500px;
  resize: none;
  padding: 10px;
}

input {
  outline: none;
  border: 1px solid black;
  padding: 10px;
}
</style>